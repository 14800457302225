@import "../../../assets/styles/abstracts/variables";

.footer {
  border-top: 1px solid $c-border;

  .wrapper {
    padding: 24px 0 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    nav ul {
      display: flex;

      li a {
        text-decoration: none;
        color: $c-white;
        margin-right: 30px;

        &:hover {
          color: $c-primary-hover !important;
        }
      }

      .contactMail {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .mail {
          color: #8A9A98 !important;
        }
      }
    }
  }
}
