@import "../../../assets/styles/abstracts/variables";

.button {
  padding: 12px 33px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: $c-white;
  border-radius: 50px;
  border: 0;
  background-color: $c-primary;
  cursor: pointer;
}
