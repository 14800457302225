@import "../../../assets/styles/abstracts/variables";

.logo {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: 28px;
    font-weight: bold;
    color: $c-white;
  }
}
