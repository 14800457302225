@import "./abstracts/functions";
@import "./abstracts/mixins";
@import "./abstracts/variables";
@import "./base/animations";
@import "./base/base";
@import "./base/typography";
@import "./base/utility";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $c-bg;
  color: $c-text;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

br {
  width: 100%;
  color: $c-border;
}

.main-content {
  min-height: calc(100dvh - 185px);
}

.activeLink {
  color: $c-primary !important;
}

.contentWrapper {
  padding: 0 48px;
}

.carousel {
  display: flex;
  flex-wrap: wrap;

  .slider-wrapper {
    width: 100%;
    order: 1;
    margin-bottom: 100px !important;
  }

  .prevBtnWrapper {
    padding-right: 32px;
    width: 50%;
    order: 2;
    text-align: end;

    .prevBtn {
      background-color: transparent;
      border: 0;
      transform: rotate(90deg);
      outline: none;
      cursor: pointer;
    }
  }

  .nextBtnWrapper {
    padding-left: 32px;
    width: 50%;
    order: 3;

    .nextBtn {
      background-color: transparent;
      border: 0;
      transform: rotate(-90deg);
      outline: none;
      cursor: pointer;
    }
  }
}
