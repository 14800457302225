@import "../../../assets/styles/abstracts/variables";

.header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $c-border;

  .logoNavWrapper {
    display: flex;
    gap: 30px;
  }

  .btnContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
