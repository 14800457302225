/* BREAKPOINTS */
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;

$c-bg: #161616;
$c-primary: #0f9985;
$c-primary-dark: #182d2a;
$c-primary-hover: #1ab58e;
$c-text: #d7e5e3;
$c-white: #ffffff;
$c-border: rgba(
  $color: #91b6b0,
  $alpha: 0.2,
);
