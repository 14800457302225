@import "../../../assets/styles/abstracts/variables";

.navbar {
  display: flex;

  li {
    display: flex;
    align-items: center;

    & > a {
      color: $c-white;
      padding: 16px;
      text-decoration: none;

      &:hover {
        color: $c-primary-hover !important;
      }
    }
  }
}
